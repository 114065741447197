<template>
  <div class="file-iframe-wrap">
    <template v-if="!isMobileFunc()">
      <iframe v-if="fileBase64 && !loadingFile" class="file-iframe" width='100%' height='500'
              :src="fileBase64"></iframe>
    </template>

    <div v-if="isMobileFunc()">
      <div class="download-mobile-file">
        {{$t('common_downloadMobileFile.localization_value.value')}}
      </div>
    </div>

    <NotFoundModule class="error-component" v-else-if="fileBase64 === false && loadingFile"/>
  </div>
</template>

<script>
  import NotFoundModule from "../../../NotFoundModule/NotFoundModule";
  import {mixinDetictingMobile} from "../../../../../mixins/mobileFunctions";

  export default {
    name: "GetPdfFile",

    components: {
      NotFoundModule
    },

    mixins: [mixinDetictingMobile],

    data() {
      return {
        loadingFile: true,
        fileBase64: null,
      }
    },

    beforeCreate() {
      document.title = this.$t('common_getFileTitle.localization_value.value');
    },

    mounted() {

      switch (this.$route.params.type) {
        case 'thermalPrint':
          this.generateThermalPrint()
          break
        case 'paymentInvoicePrint':
          this.generatePaymentInvoicePrint()
          break
        case 'invoicePrint':
          this.generateInvoicePrint()
          break
        case 'my-payments-authorize-print':
          this.generateAuthorizePrint()
          break
        case 'express-order-thermal-print':
          this.generateExpressOrderThermalPrint()
          break
        case 'inbound-order-thermal-print':
          this.generateInboundOrderThermalPrint()
          break
        case 'fbm-order-thermal-print':
          this.generateFBMOrderThermalPrint()
          break
        case 'fbm-order-generate-pdf':
          this.generateFBMOrderICreateLabelPdf()
          break
        case 'fbm-order-generate-barcode-pdf':
          this.generateFBMOrderICreateLabelBarcodePdf()
          break
        case 'consolidation-thermal-print':
          this.generateConsolidationThermalPrint()
          break
        case 'consolidation-inner-labels':
          this.generateConsolidationInnerLabels()
          break
        case 'get-labels-fast-order':
          this.generateFastOrderGetLabels()
          break
        case 'get-labels-express-fast-order':
          this.generateExpressFastOrderGetLabels()
          break
        // case 'order-price-print':
        //   this.generateOrderPriceBarcodes()
        //   break
        case 'order-size':
          this.generateOrderSize()
          break
        case 'packing-type':
          this.generatePackingType()
          break
        case 'express-labels-print':
          this.generateExpressLabels()
          break
        case 'poland-invoice-report':
          this.generatePolandInvoiceReport()
          break
      }

    },

    methods: {
      generatePolandInvoiceReport() {
        this.$store.dispatch('getPolandInvoiceReport').then(response => {
          if (!this._.has(response, 'data')) {
            this.loadingFile = false
            this.fileBase64 = false
            return
          }

          this.openDownloadedFileInIframe(response)
        })
      },

      generateThermalPrint() {
        let data = {
          'name': this.$route.query.name,
          'upc': this.$route.query.upc,
          'sku': this.$route.query.sku,
          'quantity': parseInt(this.$route.query.quantity),
        }
        this.$store.dispatch('thermalPrint', data).then(response => {
          if (!this._.has(response, 'data')) {
            this.loadingFile = false
            this.fileBase64 = false
            return
          }

          this.openDownloadedFileInIframe(response)
        })
      },

      generateExpressLabels() {
        let data = {
          'orderExpressIds': this.$route.query.id,
          'betweenCreatedAt': this.$route.query.date,
        }
        this.$store.dispatch('expressGenerateLabels', data).then(response => {
          if (!this._.has(response, 'data')) {
            this.loadingFile = false
            this.fileBase64 = false
            return
          }

          this.openDownloadedFileInIframe(response)
        })
      },


      generateInvoicePrint() {
        let data = {
          'ids': this.$route.query.ids,
          'date': this.$route.query.date,
          'first_last_name': this.$route.query.fullName,
          'company_name': this.$route.query.companyName,
          'email': this.$route.query.email,
          'address': this.$route.query.address,
          'city': this.$route.query.city,
          'region': this.$route.query.region,
          'country_code': this.$route.query.countryCode,
          'zip': this.$route.query.zip,
          'vat_number': this.$route.query.vatNumber,
        }

        let ids = data.ids
        if(!Array.isArray(data.ids))
          ids = data.ids.split(',')

        let idsParams = ids.map(item => 'invoice_ids[]=' + item).join('&')

        let dateParam = `invoice_date=${data.date}`
        let fullNameParam = `first_last_name=${data.first_last_name}`
        let companyNameParam = `company_name=${data.company_name}`
        let emailParam = `email=${data.email}`
        let addressParam = `address=${data.address}`
        let cityParam = `city=${data.city}`
        let regionParam = `region=${data.region}`
        let countryCodeParam = `country_code=${data.country_code}`
        let zipParam = `zip=${data.zip}`
        let vatNumberParam = `vat_number=${data.vat_number}`

        let dataParams = `?${idsParams}&${dateParam}&${fullNameParam}&${companyNameParam}&${emailParam}&${addressParam}&${cityParam}&${regionParam}&${countryCodeParam}&${zipParam}&${vatNumberParam}`

        this.$store.dispatch('getInvoicesPrintPdf', dataParams).then(response => {
          if (!this._.has(response, 'data')) {
            this.loadingFile = false
            this.fileBase64 = false
            return
          }

          this.openDownloadedFileInIframe(response)

        })
      },


      generatePaymentInvoicePrint() {
        let data = {
          'paymentTransactionIds': this.$route.query.ids.split(','),
          'invoiceDate': this.$route.query.date,
          'first_last_name': this.$route.query.fullName,
          'company_name': this.$route.query.companyName,
          'email': this.$route.query.email,
          'address': this.$route.query.address,
          'city': this.$route.query.city,
          'region': this.$route.query.region,
          'country_code': this.$route.query.countryCode,
          'zip': this.$route.query.zip,
          'vat_number': this.$route.query.vatNumber,
        }

        // let ids = data.ids
        // if(!Array.isArray(data.ids))
        //   ids = data.ids.split(',')
        //
        // let idsParams = ids.map(item => 'invoice_ids[]=' + item).join('&')
        //
        // let dateParam = `invoice_date=${data.date}`
        // let fullNameParam = `first_last_name=${data.first_last_name}`
        // let companyNameParam = `company_name=${data.company_name}`
        // let emailParam = `email=${data.email}`
        // let addressParam = `address=${data.address}`
        // let cityParam = `city=${data.city}`
        // let regionParam = `region=${data.region}`
        // let countryCodeParam = `country_code=${data.country_code}`
        // let zipParam = `zip=${data.zip}`
        // let vatNumberParam = `vat_number=${data.vat_number}`
        //
        // let dataParams = `?${idsParams}&${dateParam}&${fullNameParam}&${companyNameParam}&${emailParam}&${addressParam}&${cityParam}&${regionParam}&${countryCodeParam}&${zipParam}&${vatNumberParam}`

        this.$store.dispatch('printPdfMyPaymentsInvoice', {data, type: this.$route.query.type}).then(response => {
          if (!this._.has(response, 'data')) {
            this.loadingFile = false
            this.fileBase64 = false
            return
          }

          this.openDownloadedFileInIframe(response)

        })
      },

      generateAuthorizePrint() {

        let data = {
          'paymentSystem': this.$route.query.paymentSystem,
          'paymentTransactionIds': this.$route.query.paymentTransactionIds,
          'date': this.$route.query.date,
        }

        this.$store.dispatch('getExportMyPaymentsPdf', data).then(response => {
          if (!this._.has(response, 'data')) {
            this.loadingFile = false
            this.fileBase64 = false
            return
          }

          this.openDownloadedFileInIframe(response)
        })
      },

      generateExpressOrderThermalPrint() {
        let data = {
          'id': this.$route.query.id,
          'type': this.$route.query.type,
        }
        this.$store.dispatch('getExpressOrderThermalPrintPdf', data).then(response => {
          if (!this._.has(response, 'data')) {
            this.loadingFile = false
            this.fileBase64 = false
            return
          }

          console.log(response);

          this.openDownloadedFileInIframe(response)
        })
      },

      generateInboundOrderThermalPrint() {
        let data = {
          'id': this.$route.query.id,
          'type': this.$route.query.type,
        }
        this.$store.dispatch('getInboundOrderFile', data).then(response => {
          if (!this._.has(response, 'data')) {
            this.loadingFile = false
            this.fileBase64 = false
            return
          }

          console.log(response);

          this.openDownloadedFileInIframe(response)
        })
      },

      generateConsolidationThermalPrint() {
        let data = {
          'id': this.$route.query.id,
          'type': this.$route.query.type,
        }
        this.$store.dispatch('getConsolidationFile', data).then(response => {
          if (!this._.has(response, 'data')) {
            this.loadingFile = false
            this.fileBase64 = false
            return
          }

          console.log(response);

          this.openDownloadedFileInIframe(response)
        })
      },

      generateConsolidationInnerLabels() {
        let data = {
          'id': this.$route.query.id,
          'type': this.$route.query.type,
        }
        this.$store.dispatch('getConsolidationInnerLabels', data).then(response => {
          if (!this._.has(response, 'data')) {
            this.loadingFile = false
            this.fileBase64 = false
            return
          }

          console.log(response);

          this.openDownloadedFileInIframe(response)
        })
      },

      generateFBMOrderThermalPrint() {
        let data = {
          'ids': this.$route.query.ids.split(','),
          'format': this.$route.query.format,
        }
        this.$store.dispatch('getOrderFBMThermalPrint', data).then(response => {
          if (!this._.has(response, 'data')) {
            this.loadingFile = false
            this.fileBase64 = false
            return
          }

          this.openDownloadedFileInIframe(response)
        })
      },

      generateFBMOrderICreateLabelPdf() {
        let data = {
          'ids': this.$route.query.ids.split(','),
          'format': this.$route.query.format,
        }
        this.$store.dispatch('getOrderFBMCreateLabelPdf', data).then(response => {
          if (!this._.has(response, 'data')) {
            this.loadingFile = false
            this.fileBase64 = false
            return
          }

          this.openDownloadedFileInIframe(response)
        })
      },

      generateFBMOrderICreateLabelBarcodePdf() {
        let data = {
          'ids': this.$route.query.ids.split(','),
          'format': this.$route.query.format,
        }
        this.$store.dispatch('getOrderFBMCreateLabelBarcodePdf', data).then(response => {
          if (!this._.has(response, 'data')) {
            this.loadingFile = false
            this.fileBase64 = false
            return
          }

          this.openDownloadedFileInIframe(response)
        })
      },

      generateFastOrderGetLabels() {
        let id = this.$route.query.id

        this.$store.dispatch('getFastOrdersLabel', id).then(response => {
          if (!this._.has(response, 'data')) {
            this.loadingFile = false
            this.fileBase64 = false
            return
          }

          this.openDownloadedFileInIframe(response)
        })
      },

      generateExpressFastOrderGetLabels() {
        let id = this.$route.query.id

        this.$store.dispatch('getExpressFastOrdersLabel', id).then(response => {
          if (!this._.has(response, 'data')) {
            this.loadingFile = false
            this.fileBase64 = false
            return
          }

          this.openDownloadedFileInIframe(response)
        })
      },

      generateOrderSize() {
        let type = this.$route.query.type
        let sizeType = this.$route.query.sizeType

        this.$store.dispatch('getOrderSizePrint', {type, sizeType}).then(response => {
          if (!this._.has(response, 'data')) {
            this.loadingFile = false
            this.fileBase64 = false
            return
          }

          this.openDownloadedFileInIframe(response)
        })
      },

      generatePackingType() {
        let id = this.$route.query.id

        this.$store.dispatch('getPackingTypePrint', id).then(response => {
          if (!this._.has(response, 'data')) {
            this.loadingFile = false
            this.fileBase64 = false
            return
          }

          this.openDownloadedFileInIframe(response)
        })
      },

      openDownloadedFileInIframe(response){
        switch (this.getResponseStatus(response)) {
            /**
             * Success
             */
          case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
            let data = this.getRespData(response) || response.data

            let responseFileContent = data?.['fileContentBase64'] || data

            let byteCharacters = atob(responseFileContent)
            let byteNumbers = new Array(byteCharacters.length)
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i)
            }

            let byteArray = new Uint8Array(byteNumbers)
            let file = new Blob([byteArray], { type: 'application/pdf;base64' })

            this.fileBase64 = URL.createObjectURL(file)

            if (this.isMobileFunc()){
              let a = document.createElement("a")
              a.style = "display: none";
              a.href = this.fileBase64;
              a.download = 'file.pdf';
              a.click();
            }
            this.loadingFile = false
            break
          }
            /**
             * Validation Error
             */
          case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
            let errors = response.response.data.errors;
            this.notifyErrorHelper(errors)
            break
          }
            /**
             * Undefined Error
             */
          default: {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        }
      },

      // generateOrderPriceBarcodes() {
      //   let data = {
      //     'type': this.$route.query.type,
      //     'format': this.$route.query.format,
      //   }
      //
      //   this.$store.dispatch('getOrderPriceFile', data).then(response => {
      //     if (!this._.has(response, 'data')) {
      //       this.loadingFile = false
      //       this.fileBase64 = false
      //       return
      //     }
      //
      //     this.openDownloadedFileInIframe(response)
      //   })
      // },

    },

  }
</script>

<style scoped lang="scss">

  .file-iframe-wrap {
    display: flex;
  }

  .file-iframe {
    border: 0;
    width: 100%;
    height: 100vh;
  }

  .error-component {
    margin: 0 auto;
  }

  .download-mobile-file {
    padding-top: 15%;
    padding-right: 15px;
    padding-left: 15px;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    background: #f8f4ee;
    font-family: Roboto, sans-serif;
    font-weight: 600;
    color: #232323;
    text-align: center;
    overflow: hidden;
  }
</style>
